<template>
  <div id="addUser">
    <v-card>
      <v-form ref="form" @submit.prevent="add()">
        <v-card-title>Yeni Kullanıcı</v-card-title>
        <v-divider />
        <v-card-text>
          <div class="d-flex justify-space-around">
            <v-text-field
              id="firstName"
              v-model="form.firstName"
              label="Ad"
              name="firstName"
              prepend-icon="person"
              type="text"
              required
              :rules="firstNameRules"
            />
            <v-text-field
              id="lastName"
              v-model="form.lastName"
              label="Soyad"
              name="lastName"
              type="text"
              required
              :rules="lastNameRules"
              class="ml-4"
            />
          </div>
          <v-text-field
            id="email"
            v-model="form.email"
            label="e-Posta"
            name="email"
            prepend-icon="alternate_email"
            type="text"
            required
            :rules="emailRules"
          />
          <div class="d-flex justify-space-around">
            <v-text-field
              id="password"
              v-model="form.password"
              label="Parola"
              name="password"
              prepend-icon="lock"
              type="password"
              required
              min="6"
              :rules="passwordRules"
            />
            <v-text-field
              id="passwordConfirm"
              v-model="form.passwordConfirm"
              label="Parola Onayı"
              name="passwordConfirm"
              type="password"
              required
              min="6"
              :rules="passwordConfirmRules"
              class="ml-4"
            />
          </div>
          <v-select
            :items="roles"
            v-model="form.roles"
            item-text="title"
            item-value="id"
            label="Roller"
            multiple
            prepend-icon="assignment_ind"
            :rules="roleRules"
            required
          ></v-select>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="close">İptal Et</v-btn>
          <v-btn color="primary" type="submit" text :disabled="submitDisabled"
            >Ekle</v-btn
          >
        </v-card-actions>
      </v-form>
    </v-card>
  </div>
</template>

<script>
import firebase from "@/plugins/firebase";

export default {
  data: () => ({
    roles: [
      { id: "admin", title: "Sistem Yöneticisi" },
      { id: "student", title: "Öğrenci" },
      { id: "instructor", title: "Eğitmen" }
    ],
    form: {
      firstName: "",
      lastName: "",
      password: "",
      passwordConfirm: "",
      email: "",
      roles: []
    },
    submitDisabled: false
  }),
  computed: {
    firstNameRules() {
      return [v => !!v || "Lütfen adınızı yazınız"];
    },
    lastNameRules() {
      return [v => !!v || "Lütfen soyadınızı yazınız"];
    },
    emailRules() {
      return [
        v => !!v || "Lütfen e-postanızı yazınız",
        v => /.+@.+\..+/.test(v) || "Geçersiz e-posta adresi"
      ];
    },
    passwordRules() {
      return [
        v => !!v || "Lütfen parolanızı yazınız",
        v => (v && v.length >= 6) || "En az 6 karakter olmalıdır"
      ];
    },
    passwordConfirmRules() {
      return [
        v => !!v || "Lütfen parolanızı onaylayınız",
        v => (v && v.length >= 6) || "En az 6 karakter olmalıdır",
        v => v === this.form.password || "Parola onaylanmadı"
      ];
    },
    roleRules() {
      return [v => (v && v.length > 0) || "Lütfen rol belirtiniz."];
    }
  },
  methods: {
    close() {
      this.reset();
      this.$emit("close");
    },
    refresh() {
      this.$emit("refresh");
    },
    validate() {
      return this.$refs.form.validate();
    },
    reset() {
      //this.$refs.form.reset();

      this.form.firstName = "";
      this.form.lastName = "";
      this.form.email = "";
      this.form.password = "";
      this.form.passwordConfirm = "";
      this.form.roles = [];

      this.resetValidation();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    async add() {
      if (this.validate()) {
        this.submitDisabled = true;
        this.$notify("Kullanıcı ekleniyor. Lütfen bekleyin...");

        try {
          const newUser = {
            email: this.form.email,
            emailVerified: true,
            password: this.form.password,
            disabled: false,
            firstName: this.form.firstName,
            lastName: this.form.lastName,
            roles: this.form.roles
          };

          const addUserFunc = firebase
            .functions()
            .httpsCallable("akademi-auth-addUser");
          await addUserFunc(newUser);

          this.submitDisabled = false;
          this.refresh();
          this.$notify({
            text: "Yeni kullanıcı oluşturuldu.",
            type: "success"
          });
          this.close();
        } catch (err) {
          this.submitDisabled = false;
          this.$notify({
            type: "error",
            title: "Hata!",
            text: "Kullanıcı oluşturulamadı."
          });
          console.log(`HATA: Kullanıcı oluşturulamadı. ${err}`);
        }
      }
    }
  }
};
</script>
