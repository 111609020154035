<template>
  <div id="users">
    <v-container class="my-4">
      <v-row>
        <v-col cols="12">
          <v-card>
            <v-card-title>
              <v-text-field
                v-model="search"
                append-icon="search"
                label="Ara"
                single-line
                hide-details
              />
              <v-spacer />
              <v-btn
                color="primary"
                text
                @click.stop="
                  () => {
                    dialogContent = 'Add';
                    dialog = true;
                  }
                "
              >
                <v-icon class="mr-2">person_add</v-icon>
                <span>Kullanıcı Ekle</span>
              </v-btn>
              <v-btn icon @click="getItems">
                <v-icon class="mr-2">refresh</v-icon>
              </v-btn>
            </v-card-title>
            <v-data-table
              :headers="headers"
              :items="items"
              :search="search"
              :loading="loading"
              loading-text="Yükleniyor..."
            >
              <template v-slot:[`item.actions`]="{ item }">
                <v-menu close-on-content-click>
                  <template v-slot:activator="{ on }">
                    <v-btn icon v-on="on">
                      <v-icon>more_vert</v-icon>
                    </v-btn>
                  </template>
                  <v-list dense>
                    <v-list-item @click="addRemoveCourse(item)">
                      <v-list-item-icon>
                        <v-icon>visibility</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>Eğitim Ekle/Sil</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="editItem(item)">
                      <v-list-item-icon>
                        <v-icon>create</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>Düzenle</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="deleteItem(item)">
                      <v-list-item-icon>
                        <v-icon>delete</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>Sil</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-dialog v-model="dialog" max-width="600" persistent>
      <component
        :is="dialogContent"
        @close="dialog = false"
        :user="selectedItem"
        @refresh="getItems"
      />
    </v-dialog>

    <confirm ref="confirm" />
  </div>
</template>

<script>
import firebase from "@/plugins/firebase";
import Add from "./Add.vue";
import Edit from "./Edit.vue";
import AddRemoveCourse from "./AddRemoveCourse.vue";
import Confirm from "@/components/Confirm";

export default {
  data() {
    return {
      search: "",
      loading: false,
      headers: [
        {
          text: "Ad",
          align: "start",
          filterable: true,
          value: "displayName",
          width: "50%"
        },
        { text: "e-Posta", value: "email", width: "50%" },
        { text: "Oluşturulma", value: "creationTime", width: "160px" },
        { text: "Son Oturum", value: "lastSignInTime", width: "160px" },
        { text: "", value: "actions", sortable: false, width: "50px" }
      ],
      items: [],
      selectedItem: {},
      dialog: false,
      dialogContent: ""
    };
  },

  methods: {
    async getItems() {
      this.loading = true;

      try {
        // Get all users
        const getUsersFunc = firebase
          .functions()
          .httpsCallable("akademi-auth-getAllUsers");
        const result = await getUsersFunc();

        this.loading = false;

        if (result.data) {
          this.items = [];
          result.data.forEach(user => {
            this.items.push({
              id: user.uid,
              displayName: user.displayName,
              email: user.email,
              lastSignInTime:
                user.lastSignInTime != null
                  ? new Date(user.lastSignInTime).toLocaleString("tr-TR")
                  : user.lastSignInTime,
              creationTime:
                user.creationTime != null
                  ? new Date(user.creationTime).toLocaleString("tr-TR")
                  : user.creationTime
            });
          });
        }
      } catch (err) {
        this.loading = false;
        this.$notify({
          title: "Hata!",
          text: "Kullanıcı listesi alınamadı.",
          type: "error"
        });
      }
    },

    async deleteItem(item) {
      const confirmation = await this.$refs.confirm.open(
        "Kullanıcı Silme",
        "Bu işlem geri alınamaz. Devam etmek istediğinizden emin misiniz?",
        {
          color: "red"
        }
      );

      if (confirmation) {
        this.$notify("Kullanıcı siliniyor. Lütfen bekleyin...");

        try {
          const deleteUserFunc = firebase
            .functions()
            .httpsCallable("akademi-auth-deleteUser");
          await deleteUserFunc(item.id);

          this.$notify({
            text: "Kullanıcı başarıyla silindi.",
            type: "success"
          });

          this.getItems();
        } catch (err) {
          this.$notify({
            title: "Hata!",
            text: "Kullanıcı silinemedi.",
            type: "error"
          });
        }
      }
    },

    async editItem(item) {
      this.$notify("Kullanıcı bilgileri alınıyor...");

      try {
        // Get user
        const getUserFunc = firebase
          .functions()
          .httpsCallable("akademi-auth-getUser");
        const result = await getUserFunc(item.id);
        const user = result.data.user;
        this.selectedItem = {
          uid: user.uid,
          firstName: user.firstName,
          lastName: user.lastName,
          email: user.email,
          phoneNumber: user.phoneNumber,
          emailVerified: user.emailVerified,
          photoURL: user.photoURL,
          disabled: user.disabled,
          roles: user.roles
        };

        this.$notify({
          clean: true
        });

        // Open dialog
        this.dialogContent = "Edit";
        this.dialog = true;
      } catch (error) {
        this.$notify({
          title: "Hata!",
          text: "Kullanıcı bilgileri alınamadı.",
          type: "error"
        });
      }
    },

    async addRemoveCourse(item) {
      this.selectedItem = {
        id: item.id,
        displayName: item.displayName,
        email: item.email
      };

      // Open dialog
      this.dialogContent = "AddRemoveCourse";
      this.dialog = true;
    }
  },

  mounted() {
    this.getItems();
  },

  components: {
    Add,
    Edit,
    AddRemoveCourse,
    Confirm
  }
};
</script>
